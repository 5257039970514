<template> 

  <div class="container pt-4 pb-5">

    <div v-if="!selectedSchema">

      Wybierz element lejka sprzedażowego:
 
      <b-list-group class="mt-3">
        <b-list-group-item :class="{ 'text-primary': (item.type == 'label'), 'pl-3': (item.type !== 'label') }"  v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle" v-if="item.icon">{{ item.icon }}</span> {{ item.name }}
          <small v-if="item.description"><br/>{{ item.description }}</small>
        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
         
          </h2>
          <div v-if="this.selectedSchema.description"><br/>{{ this.selectedSchema.description }}</div>
          <hr/>

          <div class="mt-4">

            <div v-if="this.selectedSchema.fields.includes('PRODUKT')">

              <label>Wpisz tytuł ebooka:</label>
              
              <b-input-group>
          
                <b-form-input v-model="produkt" ref="produkt" placeholder=""></b-form-input>
                
              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('KLIENT')" class="mt-3">

              <label>Wstaw nazwę grupy docelowej. Do kogo chcesz trafić z reklamą i lead magnetem? Czym interesują się te osoby?</label>

              <b-input-group>

                <b-form-input v-model="klient" ref="klient" placeholder="np. mieszkańcy domów jednorodzinnych, księgowe"></b-form-input>
                
              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('EBOOK')" class="mt-3">

              <label>Napisz jaki e-book dajesz w prezencie? – wpisz tytuł</label>

              <b-input-group>

                <b-form-input v-model="ebook" ref="ebook" placeholder="np. 21 porad jak schudnąć"></b-form-input>

              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('BRANZA')" class="mt-3">

              <label>W jakiej branży działasz?</label>

              <b-input-group>

                <b-form-input v-model="branza" ref="branza" placeholder="np. nieruchomości"></b-form-input>

              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('OTO')" class="mt-3">

              <label>Wpisz tytuł swojego produktu One Time Offer:</label>

              <b-input-group>

                <b-form-input v-model="oto" ref="oto" placeholder="np. 101 pomysłów na wystrój wnętrza"></b-form-input>

              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('DARMOWY_EBOOK')" class="mt-3">

              <label>Wpisz tytuł, nazwę swojego lead magnetu:</label>

              <b-input-group>

                <b-form-input v-model="darmowyEbook" ref="darmowyEbook" placeholder="np. Darmowy ebook 21 porad jak uczyć się szybciej"></b-form-input>

              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('PROBLEMY')" class="mt-3">

              <label>Jakie 3 największe problemy mają twoi idealni klienci?</label>

              <b-input-group>

                <b-form-input v-model="problemy" ref="problemy" placeholder="np. brak motywacji"></b-form-input>

              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('MIESIAC')" class="mt-3">

              <label>W jakim miesiącu organizujesz wyprzedaż?</label>

              <b-input-group>

                <b-form-input v-model="miesiac" ref="miesiac" placeholder="np. czerwiec"></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz język:</label>

              <b-input-group>

                <b-form-select v-model="lang" ref="lang" :options="languages" placeholder="np. napisz mi rozdział książki na temat "></b-form-select>

              </b-input-group>

            </div>

            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje treść <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana treść</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>
  import { languages } from './../../../resources/languages.js';

  export default {
    name: 'AppEcomFunnelGeneratorAI',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        produkt: "",
        klient: "",
        branza: "",
        ebook: "",
        oto: "",
        darmowyEbook: "",
        problemy: "",
        miesiac: "",
        pasteText: "",
        lang: "Polski",
        languages,
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
        schema: [
          {
            name: "Lead magnet AI",
            icon: "chevron_right",
            fields: ["KLIENT", "PRODUKT"],
            command: "Stwórz 10 pomysłów na darmowy ebook, który przyciągnie mi potencjalnych klientów w branży sprzedającej [PRODUKT]. Odbiorcą ebooka będzie osoba [KLIENT]."
          },
          {
            name: "Landing Pages AI",
            icon: "chevron_right",
            fields: ["EBOOK"],
            command: "Napisz treść strony landing pages, której zadaniem jest przekonanie do pobrania darmowego ebooka pt. [EBOOK]"
          },
          {
            name: "One Time Offer AI",
            icon: "chevron_right",
            fields: ["KLIENT", "BRANZA"],
            command: "Stwórz 10 pomysłów na ebooka cyfrowy ebook, kurs video który zaproponujesz dla branży [BRANZA] i dla klienta [KLIENT]"
          },
          {
            name: "Landing One Time Offer AI",
            icon: "chevron_right",
            fields: ["OTO"],
            command: "Stwórz mi tekst oferty ebooka One Time Offer dla ebooka [OTO]. Pamiętaj o tekście w stylu – poczekaj chwilę mam dla Ciebie niesamowity prezent, chce Ci oddać mój najlepszy kurs na okazyjnych warunkach. Zrób wezwanie do akcji zaznacz, że oferta może w każdym momencie zniknąć."
          },
          {
            name: "Facebook Ads Generator AI",
            icon: "chevron_right",
            fields: ["DARMOWY_EBOOK", "KLIENT"],
            command: "Napisz mi 5 wersji reklamy na Facebooku w której przekonujesz do pobrania darmowego ebooka pt. [DARMOWY_EBOOK]. Grupą docelową reklamy jest [KLIENT]."
          },
          {
            name: "E-mail Funnel AI Generator AI",
            icon: "chevron_right",
            fields: ["PROBLEMY", "BRANZA"],
            command: "Napisz mi 3 emaile edukacyjne dla osób, które posiadają następujące problemy [PROBLEMY] i są klientami branży [BRANZA]. Pisz językiem prostym i skup się na konkretnych poradach."
          },
          {
            name: "Sell E-mail Funnel AI",
            icon: "chevron_right",
            fields: ["PRODUKT"],
            command: "Napisz mi serię 3 emalii sprzedażowych w stylu Dave Ogilvy, które przekonają czytelników moich emaili do zakupu [PRODUKT] w sklepie internetowym. Przedstaw korzyści z zakupu ebooka, napisz 3 korzyści i zastosuj wezwanie do akcji. E-maile oddziel znakami ----."
          },
          {
            name: "12 X Campain Funnel AI",
            icon: "chevron_right",
            fields: ["PRODUKT", "MIESIAC"],
            command: "Napisz mi serię 3 emaili sprzedażowych do 3 dniowej kampanii wyprzedażowej w moim sklepie internetowym. Ebook, który promujemy w kampanii to [PRODUKT]. Wymyśl okazję do wyprzedaży w miesiącu [MIESIAC]. E-maile oddziel znakami ----."
          }
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
  
        query = this.selectedSchema.command;

    
        if(this.$refs.produkt)
          query = query.replaceAll('[PRODUKT]', this.$refs.produkt.value)

        if(this.$refs.klient)
          query = query.replaceAll('[KLIENT]', this.$refs.klient.value)

        if(this.$refs.ebook)
          query = query.replaceAll('[EBOOK]', this.$refs.ebook.value)

        if(this.$refs.branza)
          query = query.replaceAll('[BRANZA]', this.$refs.branza.value)

        if(this.$refs.oto)
          query = query.replaceAll('[OTO]', this.$refs.oto.value)

        if(this.$refs.darmowyEbook)
          query = query.replaceAll('[DARMOWY_EBOOK]', this.$refs.darmowyEbook.value)

        if(this.$refs.problemy)
          query = query.replaceAll('[PROBLEMY]', this.$refs.problemy.value)

        if(this.$refs.miesiac)
          query = query.replaceAll('[MIESIAC]', this.$refs.miesiac.value)

        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }
 
        query = query + "Napisz to w języku " + this.lang + ".";
   

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana treść"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Oferta została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    },
    mounted() {

      if (localStorage.generators_language) {

        this.lang = localStorage.generators_language;

      } else {

        localStorage.generators_language = "Polski"
        this.lang = "Polski"
      }

    },
    watch: {

      lang(data) {

        localStorage.generators_language = data;

      },

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>