<template> 

  <div class="container pt-4">
 
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">

           
          <div class="mt-4">
              
            <div>

              <label>Wpisz tytuł rozdziału:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="topic" placeholder="np. czym jest dieta Keto"></b-form-input>
                
              </b-input-group>

            </div>

              
            <div class="mt-3">

              <label>Wybierz imitację stylu pisarza:</label>

              <b-input-group>

                <b-form-select v-model="celebrite" ref="celebrite" :options="this.celebrites"></b-form-select>
                
              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz charakter tekstu:</label>

              <b-input-group>

                <b-form-select v-model="type" ref="type" :options="this.types"></b-form-select>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz język:</label>

              <b-input-group>

                <b-form-select v-model="lang" ref="lang" :options="languages" placeholder="np. napisz mi rozdział książki na temat "></b-form-select>

              </b-input-group>

            </div>

            <br/>
 

 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje tekst dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  import { languages } from './../../../resources/languages.js';

  export default {
    name: 'AppCelebrityPostWriterAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        celebrite: "",
        celebrites: [
          "(brak)",
          "Dale Carnegie",
          "Napoleon Hill",
          "Stephen Covey",
          "Robert Kiyosaki",
          "Tony Robbins",
          "Simon Sinek",
          "Peter Drucker",
          "Malcolm Gladwell",
          "Jim Collins",
          "Zig Ziglar",
          "Seth Godin",
          "Gary Vaynerchuk",
          "Brian Tracy",
          "John C. Maxwell",
          "Robin Sharma",
          "Daniel Pink",
          "Brené Brown",
          "Timothy Ferriss",
          "Eric Ries",
          "Patrick Lencioni",
          "Jack Canfield",
          "Deepak Chopra",
          "Marcus Buckingham",
          "Daniel Goleman",
          "Chip Heath",
          "Suzy Welch",
          "Clayton Christensen",
          "Guy Kawasaki",
          "Nassim Nicholas Taleb",
          "Carol Dweck",
          "Adam Grant",
          "Mihaly Csikszentmihalyi",
          "Charles Duhigg",
          "Gretchen Rubin",
          "Ryan Holiday",
          "Brendon Burchard",
          "Ray Dalio",
          "James Clear",
          "Cal Newport",
          "Grant Cardone",
          "Les Brown",
          "Shawn Achor",
          "Mark Manson",
          "Marie Forleo",
          "Jen Sincero",
          "David Allen",
          "Jordan B. Peterson",
          "Eckhart Tolle",
          "Viktor E. Frankl",
          "Robert Cialdini",
          "Malcolm Gladwell",
          "Wayne Dyer",
          "Fiodor Dostojewski",
          "Leo Tołstoj",
          "Franz Kafka",
          "Gabriel García Márquez",
          "Miguel de Cervantes",
          "Charles Dickens",
          "Honoré de Balzac",
          "Marcel Proust",
          "Johann Wolfgang von Goethe",
          "Victor Hugo",
          "James Joyce",
          "Mark Twain",
          "Ernest Hemingway",
          "Vladimir Nabokov",
          "George Orwell",
          "William Faulkner",
          "Albert Camus",
          "Gustave Flaubert",
          "F. Scott Fitzgerald",
          "Lew Tołstoj",
          "Anton Czechow",
          "Haruki Murakami",
          "Toni Morrison",
          "Salman Rushdie",
          "Umberto Eco",
          "Thomas Mann",
          "Jean-Paul Sartre",
          "Julio Cortázar",
          "Virginia Woolf",
          "Samuel Beckett",
          "Heinrich Böll",
          "Günter Grass"
        ],
        type: "",
        types: [
          "(brak)",
          "Ekspercki",
          "Poradnikowy",
          "Humorystyczny",
          "Tutorial krok po kroku",
          "Filozoficzny",
          "Psychologiczny",
          "Naukowy",
          "Dziennikarski",
          "Historyczny",
          "Krytyczny",
          "Perswazyjny",
          "Analityczny",
          "Motywacyjny",
          "Satyryczny",
          "Refleksyjny",
          "Opisowy",
          "Narracyjny",
          "Techniczny",
          "Akademicki",
          "Publicystyczny",
          "Dydaktyczny",
          "Informacyjny",
          "Reportażowy",
          "Polemiczny",
          "Eseistyczny",
          "Aforystyczny",
          "Spekulatywny",
          "Liryczny",
          "Instruktażowy"
        ],
        lang: "Polski",
        languages,
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
         
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Napisz mi koniecznie w języku " + this.lang + "! obszerny na ponad 10 000 znaków, liczba znaków jest ważna i merytoryczny rozdział do mojej książki. Tytuł rozdziału to [WSTAW TEMAT] . Pisz w stylu autorskim i językowym [WSTAW STYL AUTORA]. Rozdział ma mieć charakter [WSTAW CHARAKTER]";
 
        if(this.$refs.celebrite)  
          query = query.replaceAll('[WSTAW STYL AUTORA]', this.$refs.celebrite.value)

        if(this.$refs.type)
          query = query.replaceAll('[WSTAW CHARAKTER]', this.$refs.type.value)


        if(this.$refs.topic)
          query = query.replaceAll('[WSTAW TEMAT]', this.$refs.topic.value)
 
 
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

        if(this.addCallToFollow) {

          query = query + "Dodaj wezwanie do obserwowania."

        }

        if(this.addHashtags) {

          query = query + "Dodaj hashtagi na końcu."

        }

 
        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany tekst"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    },
    mounted() {

      if (localStorage.generators_language) {

        this.lang = localStorage.generators_language;

      } else {

        localStorage.generators_language = "Polski"
        this.lang = "Polski"
      }

    },
    watch: {

      lang(data) {

        localStorage.generators_language = data;

      },

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>