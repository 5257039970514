<template> 

  <div class="container pt-4 pb-4">

    <div v-if="!selectedSchema">

      Wybierz element oferty:
 
      <b-list-group class="mt-3">
        <b-list-group-item :class="{ 'text-primary': (item.type == 'label'), 'pl-3': (item.type !== 'label') }"  v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle" v-if="item.icon">{{ item.icon }}</span> {{ item.name }}
          <small v-if="item.description"><br/>{{ item.description }}</small>
        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
         
          </h2>
          <div v-if="this.selectedSchema.description"><br/>{{ this.selectedSchema.description }}</div>
          <hr/>
          

          <div class="mt-4">
              
      
            <div v-if="this.selectedSchema.fields.includes('WSTAW NAZWĘ PRODUKTU')">

              <label>Wpisz nazwę ebooka:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="productName" placeholder="np. Ebook o szybkim odchudzaniu"></b-form-input>
                
              </b-input-group>

            </div>


            <div v-if="this.selectedSchema.fields.includes('WSTAW GRUPĘ DOCELOWĄ')" class="mt-3">

              <label>Wstaw nazwę grupy docelowej:</label>

              <b-input-group>

                <b-form-input v-model="group" ref="group" placeholder="np. mieszkańcy domów jednorodzinnych, księgowe"></b-form-input>
                
              </b-input-group>

            </div>


            <div v-if="this.selectedSchema.fields.includes('WSTAW PROBLEM GRUPY DOCELOWEJ')" class="mt-3">

              <label>Wpisz palący problem grupy docelowej:</label>

              <b-input-group>

                <b-form-input v-model="problem" ref="problem" placeholder="np. wolne zrzucanie kilogramów"></b-form-input>
                
              </b-input-group>

            </div>


            <div v-if="this.selectedSchema.fields.includes('WSTAW CEL')" class="mt-3">

              <label>Wpisz cel, który chce osiągnąć odbiorca:</label>

              <b-input-group>

                <b-form-input v-model="cel" ref="cel" placeholder="np. świetny wygląd, płaski brzuch"></b-form-input>
              
              </b-input-group>

            </div>


            <div v-if="this.selectedSchema.fields.includes('WSTAW CZAS EFEKTU')" class="mt-3">

              <label>Wpisz po jakim czasie pojawią się efekty:</label>

              <b-input-group>

                <b-form-input v-model="czas" ref="czas" placeholder="np. natychmiast, w czasie 48 godzin, po roku"></b-form-input>

              </b-input-group>

            </div>
            
            <div v-if="this.selectedSchema.fields.includes('WSTAW CENĘ')" class="mt-3">

            <label>Wpisz cenę ebooka:</label>

            <b-input-group>

              <b-form-input v-model="cena" ref="cena" placeholder="np. 199zł"></b-form-input>

            </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('WSTAW TON')" class="mt-3">

              <label>Wybierz ton:</label>

              <b-input-group>

                <b-form-select v-model="tone" ref="tone" :options="tones"></b-form-select>
               
              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz język:</label>

              <b-input-group>

                <b-form-select v-model="lang" size="lg" ref="lang" :options="languages" placeholder="np. napisz mi rozdział książki na temat "></b-form-select>

              </b-input-group>

            </div>

            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje ofertę dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana oferta</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>
  import { languages } from './../../../resources/languages.js';

  export default {
    name: 'AppEbookOfferGeneratorAI',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        occupation: "",
        comment: "",
        industry: "",
        name: "",
        tematyka: "",
        problem: "",
        client: "",
        w_czym_pomagasz: "",
        style: "",
        tone: "",
        pasteText: "",
        lang: "Polski",
        languages,
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        tones: [
           'Beztroski' ,
           'Buntowniczy' ,
           'Charyzmatyczny' ,
           'Dociekliwy' ,
           'Dyplomatyczny' ,
           'Dystansujący się' ,
           'Elegancki' ,
           'Emocjonalny' ,
           'Entuzjastyczny' ,
           'Euforyczny' ,
           'Humorystyczny' ,
           'Inspirujący' ,
           'Ironiczny' ,
           'Konkretny' ,
           'Krytyczny' ,
           'Melancholijny' ,
           'Naukowy' ,
           'Neutralny' ,
           'Obojętny' ,
           'Oficjalny' ,
           'Optymistyczny' ,
           'Oschły' ,
           'Perswazyjny' ,
           'Pesymistyczny' ,
           'Profesjonalny' ,
           'Prosty i zrozumiały' ,
           'Przepraszający' ,
           'Przyjazny' ,
           'Rzeczowy' ,
           'Sarkastyczny' ,
           'Sentymentalny' ,
           'Serdeczny' ,
           'Sprzedażowy' ,
           'Subtelny' ,
           'Tajemniczy' ,
           'Troskliwy' ,
           'Uczuciowy' ,
           'Wspierający' ,
           'Zabawny' ,
           'Zawoalowany' ,
           'Zawzięty' 
        ],
        selectedSchema: null,
        schema: [
          {
            name: "Wstęp do oferty",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW PROBLEM GRUPY DOCELOWEJ"],
            command: "Napisz mi 10 wstępów do oferty [WSTAW PRODUKT/USŁUGĘ] dla [WSTAW GRUPĘ DOCELOWĄ], którzy mają problem z [WSTAW PROBLEM GRUPY DOCELOWEJ] na podstawie następujących schematów. Czy tylko Ty zadajesz sobie stale pytanie: Już za chwilę dowiesz się jak: UWAGA! Za chwilę dowiesz się jak: WAŻNE! Wiadomość tylko dla [GRUPA DOCELOWA] Usiądź wygodnie, za chwilę dowiesz się: Wyłącz wszystkie rozpraszacze, bo za chwilę dowiesz się: Jeżeli jesteś [GRUPA DOCELOWA], który boryka się z [PROBLEM 1] to koniecznie przeczytaj ten tekst do końca. Jeżeli jesteś [GRUPA DOCELOWA] i chcesz [PROBLEM 1] to nie możesz pominąć tego tekstu. ALERT! Ważna wiadomość dla [GRUPA DOCELOWA], którym doskwiera problem [PROBLEM 1]. WAŻNE! Nie ignoruj tego tekstu, jeżeli jesteś [GRUPA DOCELOWA] i chcesz poradzić sobie z [PROBLEM 1]"
          },
          {
            name: "Nagłówek Główny",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW PROBLEM GRUPY DOCELOWEJ", "WSTAW CEL"],
            command: "Napisz mi 10 nagłówków copywriterskich (copywriting headline) do oferty [WSTAW NAZWĘ PRODUKTU] dla [WSTAW GRUPĘ DOCELOWĄ], którzy mają problem z [WSTAW PROBLEM GRUPY DOCELOWEJ] i chcą osiągnąć cel [WSTAW CEL] na podstawie następujących schematów: 1. Dlaczego tylko nieliczni [GRUPA DOCELOWA] potrafią [CEL 1]. 2. Poznaj najskuteczniejszy sposób na [PROBLEM 1] i [CEL 1]. 3. Jak w mniej niż [KIEDY EFEKTY] osiągnąć [CEL 1]. 4. Poznaj sekret [GRUPA DOCELOWA] którzy prawie zawsze osiągają [CEL 1]. 5. Czy można w mniej niż [KIEDY EFEKTY] zdobyć [CEL 1]? Tak, dlatego, że... 6. Śmiali się, gdy, kupowałem [PRODUKT] ale gdy zobaczyli [CEL 1] to... 7. Czy wiesz, dlaczego tylko nieliczni [GRUPA DOCELOWA] potrafią [CEL 1] w [KIEDY EFEKTY] albo krócej. 8. Do osób, które mają problem z [PROBLEM 1]. 9. Ważna wiadomość do [GRUPA DOCELOWA], którzy mają problem z [PROBLEM 1]. 10. Dlaczego 90% [GRUPA DOCELOWA] ma problem z [PROBLEM 1]. 11. Poznaj tą niepopularną metodę na [PROBLEM 1]. Efekty widoczne już po [KIEDY EFEKTY]. 12. Oto jak osiągnąć [CEL 1] inwestując w [PRODUKT]. 13. Ty, zawodowy [GRUPA DOCELOWA] w [KIEDY EFEKTY]."
          },
          {
            name: "Subnagłówek",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW PROBLEM GRUPY DOCELOWEJ", "WSTAW CEL", "WSTAW CZAS EFEKTU"],
            command: "Napisz mi 10 nagłówków copywriterskich (copywriting headline) do oferty [WSTAW NAZWĘ PRODUKTU] dla [WSTAW GRUPĘ DOCELOWĄ], którzy mają problem z [WSTAW PROBLEM GRUPY DOCELOWEJ] i chcą osiągnąć cel [WSTAW CEL] w czasie [WSTAW KIEDY OSIĄGNĄŁ EFEKT] na podstawie następujących schematów: 1. Poznaj tą niepopularną metodę na [PROBLEM 1]. Efekty widoczne już po [KIEDY EFEKTY]. 2. Oto jak osiągnąć [CEL 1] inwestując w [PRODUKT]. 3. Ty, zawodowy [GRUPA DOCELOWA] w [KIEDY EFEKTY]. 4. Jak? Przeczytaj ten tekst... 5. Jak [CEL 1] bez wydawania fortuny? 6. Zobacz jak łatwo możesz [CEL 1] w mniej niż [KIEDY EFEKTY]. 7. Poznaj unikalne rozwiązanie problemu [PROBLEM 1], które działa w [KIEDY EFEKTY] i pozwala [CEL 1]. 8. W [KIEDY EFEKTY] osiągniesz [CEL 1] Nie wierzysz? Przeczytaj to... 9. Sfrustrowany [GRUPA DOCELOWA] znalazł rozwiązanie problemu z [PROBLEM 1] Daj mi [KIEDY EFEKTY] a zobaczysz pierwsze efekty. 10. Po prostu najlepszy [PRODUKT] dla [GRUPA DOCELOWA] w tym przedziale cenowym. 11. Prawdopodobnie najlepszy [PRODUKT] dla [GRUPA DOCELOWA] jaki pojawił się na rynku. 12. W końcu dało nam się rozwiązać problem [PROBLEM 1]. 13. Miałeś ostatnio problemy z [PROBLEM 1]? Poznaj [PRODUKT], który da Ci [CEL 1] w mniej niż [KIEDY EFEKTY] W końcu [GRUPA DOCELOWA] mają coś co [CEL 1] w [KIEDY EFEKTY] albo szybciej. 14. Ważny komunikat dla [GRUPA DOCELOWA] mających problem z [PROBLEM 1]. Przeczytaj do końca..."
          },
          {
            name: "Dłuższy wstęp do oferty",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW PROBLEM GRUPY DOCELOWEJ", "WSTAW CEL"],
            command: "Napisz mi 5 wersji wstępu do oferty copywriterskiej (sales copy) ma być to oferta [WSTAW NAZWĘ PRODUKTU] dla osób [WSTAW GRUPĘ DOCELOWĄ]. Grupa docelowa chce osiągnąć cel  [WSTAW CEL] oraz mają następujące problemy problem [WSTAW PROBLEM GRUPY DOCELOWEJ] Pisz bezpośrednio na „TY”. Napisz wstęp na podstawie tych schematów: Drogi [WSTAW GRUPĘ DOCELOWĄ] Prawdopodobnie chcesz [WSTAW CEL 1] i marzysz o [WSTAW CEL 2]. Dlatego przeczytaj ten tekst do końca a dowiesz się jak osiągnąć powyższe cele. Zakładam, że od dawna planujesz osiągnąć [WSTAW CEL 1] oraz [WSTAW CEL 2]. Wiem, że nie pogniewasz się, jeżeli dodatkowo zrealizujesz kolejny cel, czyli [WSTAW CEL 3]. Jeżeli powyższe cele odpowiadają Twoim aspiracją to przeczytaj ten tekst do końca. Czy nie przyłapujesz się na tym, że masz już dosyć [WSTAW PROBLEM 1], [WSTAW PROBLEM 2] czy [WSTAW PROBLEM 3]? Rozumiem Cię, 90% naszych klientów boryka się z podobnymi niedogodnościami, dlatego przeczytaj ten tekst do końca a dowiesz się jak poradzić sobie z powyższymi wyzwaniami."
          },
          {
            name: "Pogłębienie problemu - konsekwencje zignorowania problemów",
            icon: "notes",
            fields: ["WSTAW GRUPĘ DOCELOWĄ", "WSTAW PROBLEM GRUPY DOCELOWEJ"],
            command: "Napisz mi 5 wersji tekstu copywriterskiego (sales copy) w którym przestrzegasz przed konsekwencjami zignorowania problemu [WSTAW PROBLEM GRUPY DOCELOWEJ]  przez [WSTAW GRUPĘ DOCELOWĄ].Opisz 3 konsekwencje zignorowania problemu [WSTAW PROBLEM GRUPY DOCELOWEJ] Pisz bezpośrednio na „TY”. Napisz wstęp na podstawie poniższych schematów. Oto schematy do wykorzystania: Pomyśl, jeżeli zignorujesz powyższe problemy musisz się liczyć z takimi konsekwencjami jak. Być może nie zastanawiasz się nad konsekwencjami, które wiążą się z ignorowaniem powyższych problemów. Pozwól, że je wymienię. Oczywiście możesz ignorować konsekwencje powyższych problemów. Możesz nie zauważać tych konsekwencji ale prędzej czy później cała sytuacja zemści się na Tobie."
          },
          {
            name: "Zwrot akcji - prezentacja rozwiązania problemu",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM GRUPY DOCELOWEJ"],
            command: "Napisz mi 5 wersji tekstu  copywriterskiego w którym prezentujesz ebook [WSTAW NAZWĘ PRODUKTU] jako rozwiązanie problemów [WSTAW PROBLEM GRUPY DOCELOWEJ]  Pisz bezpośrednio na „TY”. Oto schematy do wykorzystania: Na szczęście mamy dla ciebie rozwiązanie a jest nim ... Nie martw się, mamy rozwiązanie, które pomoże Ci pozbyć się wszystkich wymienionych powyżej problemów i konsekwencji. To co chcemy Ci zaproponować to ... Na szczęście istnieje proste rozwiązanie powyższych problemów i niedogodności. Mam na myśli prawdziwy hit czyli ... Pomimo, że w tym tekście skupiam się na problemach i konsekwencjach mam dla ciebie dobrą wiadomość, czyli rozwiązanie, które spowoduje, że już za chwilę możesz cieszyć się z ..."
          },
          {
            name: "Prezentacja korzyści - Magic Bullets",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW PROBLEM GRUPY DOCELOWEJ"],
            command: "Napisz mi listę 10 korzyści tzw. \"Magic bullets\". Pisz w stylu copywritera Dave Ogilvy. Używaj języka korzyści, pisz co klienta będzie z tego miał. Korzyści mają dotyczyć [WSTAW NAZWĘ PRODUKTU] pisz językiem bezpośrednim. Odbiorcą tekstu będą: [WSTAW GRUPĘ DOCELOWĄ]. Jako wstępu użyj jednego z poniższych szablonów: Oto co zyskujesz decydując się na [WSTAW NAZWĘ PRODUKTU] Oto co konkretnie zyskujesz inwestując w [WSTAW NAZWĘ PRODUKTU]. Co konkretnie otrzymujesz inwestując w [WSTAW NAZWĘ PRODUKTU]? Oto lista korzyści...Poznaj pełną listę korzyści związanych z inwestycją w [WSTAW NAZWĘ PRODUKTU]...Oto co konkretnie otrzymasz: [WSTAW KORZYŚCI]..."
          },
          {
            name: "Prezentacja ceny - ujawniasz, ile wynosi inwestycja w Twój ebook",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW CENĘ"],
            command: "Napisz 5 wersji Element oferty copywriterskiej w której prezentujesz cenę ebooka [WSTAW NAZWĘ PRODUKTU] jednocześnie pokazując, że jest to inwestycja. Cena to [WSTAW CENĘ] Użyj poniższych schematów: Prawdopodobnie zastanawiasz się, ile musisz zainwestować w proponowane rozwiązanie? możesz otrzymać za ułamek tej ceny, czyli jedyne ... Być może zastanawiasz się jaka jest cena? Po pierwsze musisz mieć świadomość, że jest to inwestycja i wynosi ona tylko ... Ile wynosi obecnie inwestycja w [WSTAW NAZWĘ PRODUKTU]? Mam dla ciebie dobrą wiadomość. Inwestycja w ebook to jedynie..."
          },
          {
            name: "Przedstawienie WARTOŚCI ebooka",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Napisz mi cześć oferty copywriterskiej w której udowadniasz, że inwestycja w [WSTAW NAZWĘ PRODUKTU] się zwróci. Pisz językiem bezpośrednim i przekonaj odbiorcę, że jest to inwestycja."
          },
          {
            name: "Ponaglenie do zakupu - presja czasu",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Napisz mi pięć 5 wersji cześć oferty copywriterskiej w której stosujesz presję czasu na zakup [WSTAW NAZWĘ PRODUKTU].  Pisz językiem bezpośrednim i przekonaj odbiorcę, że warto kupić ebook teraz."
          },
          {
            name: "Wezwanie do akcji",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Napisz 10 wersji wezwania do akcji (CTA) dla ebooka [WSTAW NAZWĘ PRODUKTU]."
          },
          {
            name: "Ośmieszenie ceny",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW CENĘ"],
            command: "Napisz 5 wersji tekstu copywriterskiego w którym ośmieszasz cenę. Pokazujesz, że lepiej jest kupić rower za [WSTAW CENĘ] bo za tą kwotę kupisz coś mniej wartościowego i nie tak fajnego jak [WSTAW NAZWĘ PRODUKTU]."
          },
          {
            name: "Neutralizacja obiekcji",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w profesjonalnego copywritera i napisz tekst w których zneutralizujesz 5 obiekcji klienta, który nie chce kupić [WSTAW NAZWĘ PRODUKTU]. Działaj skutecznie i przekonaj go do zakupu usuwając obiekcje. Pisz jeżykiem bezpośrednim i przyjaznym. Zakończ tekst zwrotem w styl: skoro już rozwiałem twoje obiekcje przejdź do zakupu."
          },
          {
            name: "Opinie",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ"],
            command: "Wciel się w klienta przedstawiciela grupy odbiorców [WSTAW GRUPĘ DOCELOWĄ] i napisz 5 opinii o ebooku [WSTAW NAZWĘ PRODUKTU]. Pisz językiem potocznym, naturalnym i zachwalającym ebook. Podaj imię i wiek autora opinii."
          },
          {
            name: "Dla kogo jest ta oferta?",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW GRUPĘ DOCELOWĄ"],
            command: "Wciel się w profesjonalnego copywritera i napisz tekst, w którym opisujesz w 5 punktach dla kogo jest dany ebook i w 5 punktach dla kogo nie jest to ebook. Napisz o ebooku [WSTAW NAZWĘ PRODUKTU]. Klientem docelowym jest [WSTAW GRUPĘ DOCELOWĄ]. Zakończ tekstem w stylu: jak widzisz jesteśmy firmą transparentną, dlatego jest to kolejny powód do zakupu."
          },
          {
            name: "Bonusy",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w kreatywnego copywritera i wymyśl 5 bonusów do oferty [WSTAW NAZWĘ PRODUKTU]."
          },
          {
            name: "Gwarancja satysfakcji",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w profesjonalnego copywritera i napisz 5 wersji gwarancji satysfakcji dla ebooka [WSTAW NAZWĘ PRODUKTU]. Użyj zwrotów [gwarancja satysfakcji, żelazna gwarancja, tytanowa gwarancja]."
          },
          {
            name: "FAQ – lista najczęstszych pytań i odpowiedzi",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w profesjonalnego copywritera i napisz 5 punktową sekcję FAQ czyli \"Frequently Asked Questions\" dla ebooka [WSTAW NAZWĘ PRODUKTU]."
          },
          {
            name: "Wezwanie do akcji",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w doświadczonego copywritera i napisz 10 wersji wezwania do akcji (CTA) dla ebooka [WSTAW NAZWĘ PRODUKTU]."
          },
          {
            name: "Post Scriptum",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w doświadczonego copywritera i napisz 10 wersji Post Scriptum dla ebooka: [WSTAW NAZWĘ PRODUKTU]. PS. Pamiętaj, że oferta znika z rynku xxxx o godzinie xx. Pospiesz się zanim cena wzrośnie. PS. Pamiętaj, że masz 100% gwarancję satysfakcji i tak naprawdę nic nie ryzykujesz. PS. Na samym końcu przypominam Ci o bonusach o łącznej wartości xx zł. Zamów teraz. PS. Pomyśl o tym, że dzięki możesz pozbyć się problemów z... PS. Pomyśl o jak o inwestycji w swoją przyszłość. PS. Przypominam Ci, że jeżeli nie podejmiesz decyzji do xxxx do godziny xx niższa cena i wartościowe bonusy przepadną. Nie wiem, kiedy kolejny raz zorganizuje my tak atrakcyjną akcję promocyjną."
          },
          {
            name: "Storytelling",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM GRUPY DOCELOWEJ"],
            command: "Wciel się w pisarza powieści i napisz emocjonalną, odnoszącą się do wszystkich zmysłów, wzruszającą historyjkę w której najpierw opisujesz negatywną sytuację związaną z problemem [WSTAW PROBLEM GRUPY DOCELOWEJ]. Bohater przez długi czas nie może znaleźć rozwiązania aż trafia na ebook [WSTAW NAZWĘ PRODUKTU] i wtedy wszystko się odmienia na lepsze. Zacznij historyjkę od słów w stylu: poznaj historię naszego klienta. Na końcu historyjki napisz, że osoba, która kupi [WSTAW NAZWĘ PRODUKTU] też może zmienić swoje życie."
          },
          {
            name: "Wyniki badań",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się doświadczonego copywritera i stwórz 3 wersje tekstu, w którym odwołujesz się do statystyk lub wyników badań, które przekonają klienta i potwierdzają zasadność kupienia ebooka [WSTAW NAZWĘ PRODUKTU]. Użyj zwrotów „czy wiedziałeś, że, to niesamowite, ale wyniki badań, według badań wszystko przemawia za tym”"
          },
          {
            name: "Dowód społeczny",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w copywritera z długoletnim doświadczenie i Stwórz 5 wersji tekstów, w których stosujesz regułę dowodu społecznego i pokazujesz ilu klientów kupiło już ebook [WSTAW NAZWĘ PRODUKTU]."
          },
          {
            name: "Twój ebook w liczbach",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w copywritera z długoletnim stażem i napisz tekst pt. nasz ebook w liczbach. W tekście tym podajesz liczby i cyfry dotyczące sprzedawanego ebooka [WSTAW NAZWĘ PRODUKTU]. Tekst ma zaciekawić potencjalnych klientów."
          },
          {
            name: "Instrukcja krok po kroku",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wciel się w copywritera z długoletnim stażem i napisz tekst pt. jak działa [WSTAW NAZWĘ PRODUKTU] krok po kroku. Pisz tak prosto, żeby zrozumiało to 7 letnie dziecko. Użyj kroków."
          },
          {
            name: "TOYC - Tip Of Your Course",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Napisz mi 5 porad w stylu Tips & Tricks na temat [WSTAW NAZWĘ PRODUKTU]. Porady mają być konkretne, zawierać konkretne wskazówki, być napisane językiem bezpośrednim. Zaczynaj od zwrotów 'czy wiedziałeś, że', 'A teraz prosta porada', 'Prosty TIP'"
          },
          {
            name: "USK - Unikalna Cecha Ebooka",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wymyśl mi 10 krótkich, kreatywnych (mają być krótkie to jest ważna komenda) USP unique selling proposition dla ebooku: [WSTAW NAZWĘ PRODUKTU]"
          },
          {
            name: "Nieistotna wada",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            command: "Wypisz mi 5 propozycji nieistotnych wad ebooka [WSTAW NAZWĘ PRODUKTU], które w ofercie zbudują zaufanie do nas, przez to, że piszemy prawdę."
          }
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
  
        query = this.selectedSchema.command;

    
        if(this.$refs.productName)
          query = query.replaceAll('[WSTAW NAZWĘ PRODUKTU]', this.$refs.productName.value)

        if(this.$refs.shortDesc)
          query = query.replaceAll('[WSTAW KRÓTKI OPIS]', this.$refs.shortDesc.value)

        if(this.$refs.group)
          query = query.replaceAll('[WSTAW GRUPĘ DOCELOWĄ]', this.$refs.group.value)
 
        if(this.$refs.tone)
          query = query.replaceAll('[WSTAW TON]', this.tone)

        if(this.$refs.cel)
          query = query.replaceAll('[WSTAW CEL]', this.$refs.cel.value)

        if(this.$refs.problem)
          query = query.replaceAll('[WSTAW PROBLEM GRUPY DOCELOWEJ]', this.$refs.problem.value)

        if(this.$refs.czas)
          query = query.replaceAll('[WSTAW CZAS EFEKTU]', this.$refs.czas.value)

        if(this.$refs.cena)
          query = query.replaceAll('[WSTAW CENĘ]', this.$refs.cena.value)

          
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

        query = query + "Napisz to w języku" + this.lang
 

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana oferta"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Oferta została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    },
    mounted() {

      if (localStorage.generators_language) {

        this.lang = localStorage.generators_language;

      } else {

        localStorage.generators_language = "Polski"
        this.lang = "Polski"
      }

    },
    watch: {

      lang(data) {

        localStorage.generators_language = data;

      },

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>