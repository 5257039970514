<template> 

  <div class="container pt-4 mb-5">
     
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz tytuł ebooka:</label>

              <b-input-group>

                <b-form-input v-model="temat" size="lg" ref="temat" placeholder=""></b-form-input>

              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Opisz w kilku słowach o czym jest ebook:</label>

              <b-input-group>

                <b-form-textarea v-model="fragment" size="lg" ref="fragment" placeholder="np. poradnik nt. gotowania dań Keto, poradnik na temat prowadzenia rozmów z pracownikami"></b-form-textarea>
                
              </b-input-group>

            </div>

            <div class="mt-3">

              <label>Wybierz język:</label>

              <b-input-group>

                <b-form-select v-model="lang" size="lg" ref="lang" :options="languages" placeholder="np. napisz mi rozdział książki na temat "></b-form-select>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje treść <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana treść</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;">

              <div v-if="result" v-html="result" class="pb-3 mb-3" style="border-bottom: 1px solid #ddd;"></div>

            </div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>
  import { languages } from './../../../resources/languages.js';

  export default {
    name: 'AppArticleSEOIdeas',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        result2: "",
        selectedText: '',
        query: "",
        fragment: "",
        temat: "",
        lang: "Polski",
        languages,
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {

      generateFinish: function() {

        if(!this.temat) return false;
        if(!this.fragment) return false;

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Zaplanuj mi kalendarz postów na Facebooka na 7 dni. Codziennie mają się pojawiać  2 posty. Posty koniecznie! napisz w języku: " + this.lang + "  i niech mają na różne sposoby przekonywać do zakupu mojej książki pt. " + this.temat + " książka dotyczy " + this.fragment;

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');


          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana treść"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {
 
    },
    mounted() {

      if (localStorage.generators_language) {

        this.lang = localStorage.generators_language;

      } else {

        localStorage.generators_language = "Polski"
        this.lang = "Polski"
      }

    },
    watch: {

      lang(data) {

        localStorage.generators_language = data;

      },

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>